export default [
  {
    title: 'Timesheets',
    route: 'timesheets',
    icon: 'ClockIcon',
  },
  {
    title: 'Invoices',
    route: 'invoices',
    icon: 'FileTextIcon',
  },
  {
    title: 'Jobs',
    route: 'jobs',
    icon: 'SearchIcon',
    classJobs: 'addClass',
  },
  {
    title: 'Requests',
    route: 'requests',
    icon: 'MailIcon',
  },
  {
    title: 'My Interviews',
    route: 'my-interviews',
    icon: 'MessageCircleIcon',
    classJobs: 'addClass',
  },
]
